import React, { Component } from 'react';
import { 
    Button,
    // TextField,
    Tooltip, 
    IconButton, 
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    TableSortLabel,
    Toolbar,
    Icon,
    FormControlLabel,
    Checkbox,
    MenuItem
} from '@material-ui/core';

import TextField from '@mui/material/TextField';
import moment from 'moment';


import { Link, Redirect } from 'react-router-dom';
import RemoveDialog from '../../../dialogs/removePopup';
import store from '../../../../store';
import { 
  OPEN_NOTIFICATION,
  OPEN_LOADING,
  CLOSE_LOADING,
  OPEN_HELP
} from '../../../../actions/actions';

import { getAllOffers, removeOffer, customeGetRequest, baseUrlUploads, updateOffer } from './../../../../service';

import PopupInfo from '../../popupInfo';

import './offerAdmin.scss';

class EnhancedTableHead extends React.Component {
    createSortHandler = property => event => {
      this.props.onRequestSort(event, property);
    };
  
    render() {
      const { onSelectAllClick, order, orderBy, numSelected, rowCount, removeOffer, location } = this.props;
  
      return (
        <TableHead>
          <TableRow className='row_block'>
            {rows.map(row => {
              return (
                <TableCell
                  key={row.id}
                  style={{borderBottom:"none"}}
                  sortDirection={orderBy === row.id ? order : false}
                >
                  <Tooltip
                    title="Sort"
                    placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={orderBy === row.id}
                      direction={order}
                      onClick={this.createSortHandler(row.id)}
                    >
                      {row.label}
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
              );
            }, this)}
            <TableCell style={{borderBottom:"none"}}>Actions</TableCell>
          </TableRow>
        </TableHead>
      );
    }
  }

function desc(a, b, orderBy) {
  if(!(a && b))
    return -1;

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array;
    stabilizedThis.sort((a, b) => {
      const order = cmp(a, b);

      if (order !== 0) return order;
      
      return a - b;
    });


  return stabilizedThis.map(el => el);
}

function getSorting(order, orderBy) {
  return order === 'Supplier' || orderBy === 'Supplier' ? order === 'desc' ? (a, b) => desc(a.Supplier, b.Supplier, 'name') : (a, b) => -desc(a.Supplier, b.Supplier, 'name') : order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: 'offer_name', numeric: false, disablePadding: true, label: 'Name' },
  { id: 'offer_code', numeric: false, disablePadding: true, label: 'Code' },
  { id: 'type_product', numeric: false, disablePadding: true, label: 'Product' },
  { id: 'prezzo', numeric: false, disablePadding: true, label: 'Prezzo' },
  { id: 'aggiornamento', numeric: false, disablePadding: true, label: 'Aggiornamento' },
  { id: 'Supplier', numeric: false, disablePadding: true, label: 'Supplier' },
  { id: 'client_type', numeric: false, disablePadding: true, label: 'Type' },
  { id: 'exp_date', numeric: false, disablePadding: true, label: 'Valid' },
  { id: 'duration', numeric: false, disablePadding: true, label: 'durata' },
];

class OfferAdmin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            order: 'asc',
            orderBy: 'name',
            selected: [],
            offers: [],
            filteredOffers: [],
            filtering: false,
            count: 0,
            page: 0,
            prev: '', 
            next: '',
            rowsPerPage: 25,
            redirect: false,
            selectedOffer: null,
            dialogRemove: false,
            removedId: null,
            searchText: localStorage.getItem("searchText"),

            statusPopupInfo: false,
            selectedOffer: null,
            showRedOffers: false
        }

        this.editOffer = this.editOffer.bind(this);
        this.search = this.search.bind(this);
        this.removeOffer = this.removeOffer.bind(this);
        this.askRemove = this.askRemove.bind(this);
        this.askCancel = this.askCancel.bind(this);
        this.openPopupInfo = this.openPopupInfo.bind(this);
        this.closePopupInfo = this.closePopupInfo.bind(this);
    }

    componentWillMount() {
      store.dispatch({ type: OPEN_LOADING, text: 'Loading offers...' });

      getAllOffers().then(result => {

        result.data.offers.map(element => {
          element.price_f0 = element.price_f0.replace('.', ',');
          element.price_f1 = element.price_f1.replace('.', ',');
          element.price_f2 = element.price_f2.replace('.', ',');
          element.price_f3 = element.price_f3.replace('.', ',');
          element.price_gas = element.price_gas.replace('.', ',');
          element.price_pcv = element.price_pcv.replace('.', ',');
          return element;
        });

        const { offers, prev, next, count } = result.data;

        this.setState({ offers, prev, next, count });
        store.dispatch({ type: CLOSE_LOADING });

        if(localStorage.getItem("searchText") != null) {
          this.search(localStorage.getItem("searchText"));
        }


      }).catch(err => {
        store.dispatch({ type: OPEN_LOADING, text: `Can't load offers` });

        setTimeout(() => {
          store.dispatch({ type: CLOSE_LOADING });
        }, 2500)
      })
    }

    editOffer(id) {
      const offer = this.state.offers.find(offer => offer.id == id );
      this.setState({ selectedOffer: offer, redirect: true });
    }

    removeOffer(event) {
      event.stopPropagation();

      const {offers, removeId: id} = this.state;

      this.askCancel();
      removeOffer(id).then(result => {
        store.dispatch({type: OPEN_NOTIFICATION, text: 'Offer been removed', open: true});
      }).catch(err => {
        this.setState({ offers });
      })

      const filteredOffers = offers.filter(offer => offer.id != id);

      this.setState({ offers: filteredOffers });
    }

    search(text) {
      this.setState({searchText: text});
      localStorage.setItem("searchText", text);

      if(text.length == 0)
        return this.setState({ filtering: false, filteredOffers: [] })

      const offers = this.state.offers;
      const values = text.split(' ');

      let filteredOffers = offers;

      for(let i = 0, leng = values.length; i < leng; i++) {
        const regExp = new RegExp(values[i], 'i');

        filteredOffers = filteredOffers.filter(offer => {
          const { 
            offer_name,
            type_product,
            Supplier,
            client_type
          } = offer;
  
          if(
            regExp.test(offer_name)
            || regExp.test(type_product)
            || Supplier && regExp.test(Supplier.name)
            || regExp.test(client_type)
          )
            return offer;
        })
      }

      this.setState({ filteredOffers, filtering: true });
    }

    handleRequestSort = (event, property) => {
      const orderBy = property;
      let order = 'desc';
  
      if (this.state.orderBy === property && this.state.order === 'desc') {
        order = 'asc';
      }
  
      this.setState({ order, orderBy });
    };

    requestNextPage() {
      const next = this.state.next;

      if(next)
        customeGetRequest(next).then(result => {
          const { offers, prev, next, count } = result.data;
  
          this.setState({ offers, prev, next, count });
          store.dispatch({ type: CLOSE_LOADING });
        }).catch(err => {
          store.dispatch({ type: OPEN_LOADING, text: `Can't load offers` });
  
          setTimeout(() => {
            store.dispatch({ type: CLOSE_LOADING });
          }, 2500)
        })
    }

    requestPrevPage() {
      const prev = this.state.prev;

      if(prev)
        customeGetRequest(prev).then(result => {
          const { offers, prev, next, count } = result.data;
  
          this.setState({ offers, prev, next, count });
          store.dispatch({ type: CLOSE_LOADING });
        }).catch(err => {
          store.dispatch({ type: OPEN_LOADING, text: `Can't load offers` });
  
          setTimeout(() => {
            store.dispatch({ type: CLOSE_LOADING });
          }, 2500)
        })
    }
    
    handleChangePage = (event, changePage) => {
      // const { page } = this.state;

      // if(page < changePage)
      //   this.requestNextPage();
      // else 
      //   this.requestPrevPage();

      this.setState({ page: changePage });
    };
  
    handleChangeRowsPerPage = event => {
      this.setState({ rowsPerPage: event.target.value });
    };


    askRemove(id) {
      this.setState({ dialogRemove: true, removeId: id});
    }
    askCancel() {
      this.setState({ dialogRemove: false, removeId: null});
    }

    openHelp() {
      store.dispatch({ type: OPEN_HELP, component: 'proposal_energia' });
    }

    openPopupInfo(offer) {
      this.setState({ selectedOfferInfo: offer, statusPopupInfo: true })
    }

    closePopupInfo() {
      this.setState({ selectedOfferInfo: null, statusPopupInfo: false })
    }
    
    render() {
        const {
            order,
            orderBy,
            selected,
            offers,
            filteredOffers,
            filtering,
            page,
            rowsPerPage,
            redirect,
            selectedOffer,
            dialogRemove,
            count,
            statusPopupInfo,
            selectedOfferInfo,
            searchText,
            showRedOffers
        } = this.state;
        const {open: loading} = store.getState().loading;

        const data = (filteredOffers[0] || filtering) ? filteredOffers : offers;

        const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage); 

        if(redirect)
          return <Redirect to={'/'+selectedOffer.id}/>

        return (
            <div className={loading ? 'hide' : 'offer_section anim_content'}>
                    {/* <Link to='/new'>
                        <Tooltip title='Add new'>
                            <Button className='add_button'>+</Button>
                        </Tooltip>
                    </Link> */}
                    <Tooltip title='Help'>
                        <Button className='how_to_button' onClick={this.openHelp} variant="fab" mini aria-label="Edit"><Icon className='offer_icon'>help_outline</Icon></Button>
                    </Tooltip>
                    <RemoveDialog open={dialogRemove} remove={this.removeOffer} close={this.askCancel}/>

                    {statusPopupInfo && <PopupInfo open={statusPopupInfo} offer={selectedOfferInfo} handleClose={this.closePopupInfo}/> }
                <div className='header'>
                  <TextField onChange={event => this.search(event.target.value)} className='search' variant="outlined" size="medium" value={searchText} label='Search'/>
                  <FormControlLabel
                    style={{ position: 'absolute', marginTop: 45 }}
                    control={
                      <Checkbox 
                        name='TEST'
                        value={showRedOffers} 
                        onClick={() => this.setState({ showRedOffers: !showRedOffers })}
                      />
                    }
                    label="Nascondi le offerte rosse"/>
                  <Link to='/new'>
                        <Tooltip title='Add new'>
                            <Button className='add_button'><span className='add_button--plusSign'>+</span><span>add new</span></Button>
                        </Tooltip>
                    </Link>
                </div>
                <div className='content'>
                {/* <Paper> */}
                <div className='tableWrapper'>
                    <div>
                    <Table aria-labelledby="tableTitle">
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={this.handleSelectAllClick}
                            onRequestSort={this.handleRequestSort}
                            rowCount={count}
                            />
                        <TableBody>
                        {stableSort(data, getSorting(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            // .sort((a, b) => {
                            //   const ratingA = a.rating || 100;
                            //   const ratingB = b.rating || 100;

                            //   return ratingA > ratingB ? 1 : ratingA === ratingB ? 0 : -1;
                            // })
                            .filter(offer => showRedOffers ? moment(offer.exp_date).format() >= moment().format() : true )
                            .map(offer => {
                              const date = new Date(offer.exp_date);
                              const day = (date.getDate() < 10) ? '0' + date.getDate(): date.getDate();
                              const month = (date.getMonth()+1 < 10) ? '0' + (date.getMonth() + 1): date.getMonth()+1;

                              const todayStart = + new Date().setHours(0,0,0,0);
                              const todayEnd = + new Date().setHours(23,59,59,999);
                              const offerExp = + new Date(offer.exp_date);

                              let offerClass = 'row_block'

                              if(offerExp >= todayStart && offerExp <= todayEnd)
                                offerClass += ' -today';
                              
                              if(offerExp < todayStart)
                                offerClass += ' -not_valid';

                              if(offerExp > todayStart && offerExp > todayEnd)
                                offerClass += ' -valid';

                              const formatedDate = `${day}/${month}/${date.getFullYear()}`;
                            return (
                                <TableRow
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={offer.id}
                                    className={offerClass}
                                    >
                                    <TableCell style={{borderBottom:"none"}} onClick={() => this.openPopupInfo(offer)}>{offer.offer_name}</TableCell>
                                    <TableCell style={{borderBottom:"none"}} onClick={() => this.openPopupInfo(offer)}>{offer.offer_code}</TableCell>
                                    <TableCell style={{borderBottom:"none"}} onClick={() => this.openPopupInfo(offer)}>{offer.type_product}</TableCell>
                                    <TableCell style={{borderBottom:"none"}}>
                                      <TextField
                                        style={{width: '150px'}}
                                        select
                                        label="Prezzo"
                                        className='select'
                                        value={offer.prezzo}
                                        onChange={ event => {
                                          const updatedOffers = offers.map(ofr => {
                                            if(ofr.id == offer.id) {
                                              ofr.prezzo = event.target.value;

                                              updateOffer(ofr.id, { prezzo: ofr.prezzo }).catch(console.log);
                                            }

                                            return ofr;
                                          })

                                          this.setState({ offers: updatedOffers });
                                        }}
                                        margin="normal"
                                        variant="outlined"
                                        size='small'
                                        >
                                        {['Fisso', 'Variabile'].map(option => (
                                            <MenuItem key={option} value={option}>
                                            {option}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    </TableCell>
                                    <TableCell style={{borderBottom:"none"}}>
                                      <TextField
                                        style={{width: '150px'}}
                                        select
                                        label="Aggiornamento"
                                        className='select'
                                        value={offer.aggiornamento}
                                        onChange={ event => {
                                          const updatedOffers = offers.map(ofr => {
                                            if(ofr.id == offer.id) {
                                              ofr.aggiornamento = event.target.value;

                                              updateOffer(ofr.id, { aggiornamento: ofr.aggiornamento }).catch(console.log);
                                            }

                                            return ofr;
                                          })

                                          this.setState({ offers: updatedOffers });
                                        }}
                                        margin="normal"
                                        variant="outlined"
                                        size='small'
                                        >
                                        {['Aggiornata', 'In scadenza'].map(option => (
                                            <MenuItem key={option} value={option}>
                                            {option}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    </TableCell>
                                    <TableCell style={{borderBottom:"none"}} onClick={() => this.openPopupInfo(offer)}>
                                      <div className='offer_supplier'>
                                        {offer.Supplier ? offer.Supplier.name : ''}
                                        { (offer.Supplier && offer.Supplier.img) && <div className='logo_supplier_section'>
                                          <div className='logo_supplier'>
                                              <img src={baseUrlUploads + offer.Supplier.img}/>
                                          </div>
                                          <div className='tooltip_image'>
                                            <img src={baseUrlUploads + offer.Supplier.img}/>
                                          </div>
                                        </div> }
                                      </div>
                                    </TableCell>
                                    <TableCell style={{borderBottom:"none"}} onClick={() => this.openPopupInfo(offer)}>{offer.client_type}</TableCell>
                                    <TableCell style={{borderBottom:"none"}} onClick={() => this.openPopupInfo(offer)}>{formatedDate}</TableCell>
                                    <TableCell style={{borderBottom:"none"}} onClick={() => this.openPopupInfo(offer)}>{offer.duration} Mese</TableCell>
                                    <TableCell style={{borderBottom:"none"}}  className='actions_table'>
                                        <Tooltip title='Edit'><Button onClick={() => this.editOffer(offer.id)} className='actionBtn action -edit' variant="fab" mini aria-label="Edit"><Icon className='offer_icon editIcon'>edit_icon</Icon></Button></Tooltip>
                                        <Tooltip title='Remove'><Button onClick={(event) => {event.stopPropagation(); this.askRemove(offer.id)}} className='actionBtn actionBtn--remove action' variant="fab" mini aria-label="Remove"><Icon className='offer_icon removeIcon'>delete</Icon></Button></Tooltip>
                                    </TableCell>
                             </TableRow>
                            );
                          })}
                          {/* {emptyRows > 0 && (                          
                            <TableRow style={{ height: 49 * emptyRows }}>
                            <TableCell colSpan={6} style={{borderBottom:"none"}} />
                            </TableRow>
                        )} */}
                        </TableBody>
                    </Table>
                    </div>
                    <TablePagination
                      component="div"
                      count={count}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      backIconButtonProps={{
                          'aria-label': 'Previous Page',
                      }}
                      nextIconButtonProps={{
                          'aria-label': 'Next Page',
                      }}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                {/* </Paper> */}
                  </div>
                <div className='actions'>
                  <a href='https://confronto.luce-gas.it' target='_blank'><Button className="defaultButton">Comparatore</Button></a>
                </div>
                </div>
            </div>
        )
    }
}

export default OfferAdmin;